<template>
  <div>
    <SfHeader
      class="sf-header--has-mobile-search header-sticky"
      :class="{ 'header-on-top': isSearchOpen }"
    >
      <template #logo>
        <HeaderLogo />
      </template>
      <template #navigation>
        <HeaderNavigation
          :menu="menu"
          :sub-header-menu="subHeaderMenu"
          :sub-menu="subMenu"
          ref="headerNavigation"
        />
      </template>
      <template #aside>
        <div class="sf-header__switchers">
          <CurrencySelector
            v-if="hasCurrencyToSelect"
            class="smartphone-only"
          />
          <StoreSwitcher v-if="hasStoresToSelect" class="smartphone-only" />
        </div>
      </template>
      <template #header-icons>
        <div class="sf-header__icons">
          <SfButton
            v-e2e="'app-header-account'"
            class="sf-button--pure sf-header__action sf-header__action--account"
            data-testid="accountIcon"
            aria-label="Account"
            @click="toggleCloseMenu('account')"
          >
            <div
              v-show="isAuthenticated"
              class="sf-header__action--account-name"
            >
              {{ fullname }}
            </div>
            <LoginIcon />
            <span>{{ $t('Account') }}</span>
          </SfButton>
          <SfButton
            class="sf-button--pure sf-header__action"
            data-testid="wishlistIcon"
            aria-label="Wishlist"
            @click="toggleCloseMenu('wishlist')"
          >
            <component
              :is="
                numberOfItemsInWishlist > 0
                  ? 'WishlistBlackIcon'
                  : 'WishlistIcon'
              "
            />
            <span>{{ $t('Wishlist') }}</span>
          </SfButton>
          <SfButton
            v-e2e="'app-header-cart'"
            class="sf-button--pure sf-header__action"
            aria-label="Toggle cart sidebar"
            @click="toggleCloseMenu('cart')"
          >
            <CartIcon />
            <span>{{ $t('Cart') }}</span>
            <SfBadge v-if="cartTotalItems" class="sf-badge--number cart-badge">
              {{ cartTotalItems }}
            </SfBadge>
          </SfButton>
        </div>
      </template>
      <template #search>
        <SearchBar
          :is-search-open="isSearchOpen"
          :show-discontinui="showDiscontinui"
          @set-is-open="isSearchOpen = $event"
          @set-search-results="productSearchResults = $event"
          @set-search-word="searchWord = $event"
          @set-not-available-product="isNotAvailableProduct = $event"
        />
      </template>
    </SfHeader>
    <SearchResults
      v-if="isSearchOpen"
      :visible="isSearchOpen"
      :search-results="productSearchResults"
      :term="searchWord"
      :is-not-available-product="isNotAvailableProduct"
      @toggleDiscontinui="(e) => (showDiscontinui = e)"
      @close="isSearchOpen = false"
    />
    <SfOverlay :visible="isSearchOpen" />
  </div>
</template>

<script>
import { SfOverlay, SfButton, SfBadge } from '@storefront-ui/vue';
import SfHeader from '~/components/storefront-ui/organisms/SfHeader.vue';
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  useFetch,
  getCurrentInstance,
  shallowRef,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import {
  useUiHelpers,
  useUiState,
  useUser,
  useCart,
  useUserWishlists,
} from '~/composables';
import { useMenu } from '@gemini-vsf/composables';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import {
  LoginIcon,
  WishlistIcon,
  WishlistBlackIcon,
  CartIcon,
} from '~/components/General/Icons';
import { useTopBar } from './Header/TopBar/useTopBar';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SfButton,
    SfBadge,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
    LoginIcon,
    WishlistIcon,
    WishlistBlackIcon,
    CartIcon,
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { proxy } = getCurrentInstance();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginAndRegisterModal,
    } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, setAuthenticatedState, user } = useUser();
    const { cartTotalItems } = useCart();
    const { menu, search: searchMenu } = useMenu('headerMenu');
    const { menu: subHeaderMenu, search: searchSubHeaderMenu } =
      useMenu('subHeaderMenu');
    const { menu: subMenu, search: searchSubMenu } = useMenu('headerMenuSub');

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();
    const searchWord = ref('');
    const isSearchOpen = ref(false);
    const productSearchResults = ref(null);
    const isNotAvailableProduct = ref(false);
    const showDiscontinui = shallowRef(false);

    const accountIcon = computed(() =>
      isAuthenticated.value ? 'profile_fill' : 'profile'
    );

    const { numberOfItemsInWishlist } = useUserWishlists();

    const handleAccountClick = async () => {
      setAuthenticatedState();
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'my-account' }));
      } else {
        toggleLoginAndRegisterModal();
      }
    };

    useFetch(async () => {
      await searchMenu({ code: 'header-menu' });
      await searchSubMenu({ code: 'sub-menu' });
      await searchSubHeaderMenu({ code: 'sub-header-menu' });
    });

    const fullname = computed(() =>
      user.value ? `${user.value?.firstname} ${user.value?.lastname}` : null
    );

    const closeMainMenu = () => {
      proxy.$refs.headerNavigation.closeFilters();
    };

    const toggleCloseMenu = (action) => {
      switch (action) {
        case 'cart': {
          toggleCartSidebar();

          break;
        }
        case 'wishlist': {
          toggleWishlistSidebar();

          break;
        }
        case 'account': {
          handleAccountClick();

          break;
        }
        default: {
          console.log(action);
        }
      }
      closeMainMenu();
    };

    return {
      fullname,
      accountIcon,
      cartTotalItems,
      menu,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      hasCurrencyToSelect,
      hasStoresToSelect,
      subHeaderMenu,
      searchWord,
      subMenu,
      numberOfItemsInWishlist,
      isNotAvailableProduct,
      toggleCloseMenu,
      showDiscontinui,
    };
  },
  watch: {
    isSearchOpen(newValue) {
      if (newValue === true) {
        this.toggleCloseMenu('search');
      }
    },
  },
});
</script>

<style lang="scss">
.sf-header {
  --header-padding: var(--spacer-sm);
  @include for-desktop {
    --header-padding: 0 var(--spacer-sm);
  }

  &.header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
  }

  &__switchers {
    display: flex;
  }

  &__action {
    &--account {
      &-name {
        display: flex;
        align-self: center;
        margin-right: 0.5rem;
      }
    }
  }

  .sf-header__icons {
    .sf-button.sf-button--pure {
      .sf-badge {
        background: var(--c-dark-grey);
        opacity: 9;
        color: var(--c-white);
        font-size: var(--font-size-10);
        font-family: var(--lato-regular);
        font-weight: normal;
        left: inherit;
        right: -1.3rem;
        top: 0.625rem;
        @include to-tablet-1500 {
          line-height: 0.938rem;
          right: -0.625rem;
          top: 0.313rem;
        }
        &.wishlist-badge {
          right: 1.063rem;
          @include to-tablet-1500 {
            right: 0.375rem;
          }
        }
      }
    }
  }
  .header-on-top {
    z-index: 2;
  }

  .cart-badge {
    position: absolute;
    bottom: 40%;
    left: 40%;
  }
}
</style>
